export const SaleExportBasResource = {
    title: 'Експорт в BAS продажів',
    fields: {
        type: {
            label: 'Тип запиту',
            type: 'select',
            options: [
                { value: 'create', label: 'Створення' },
                { value: 'update', label: 'Оновлення' },
                { value: 'delete', label: 'Видалення' },
            ],
            inputProps: {
                disabled: true
            }
        },
        status: {
            label: 'Статус',
            type: 'select',
            options: [
                { value: 'Success', label: 'Успіх' },
                { value: 'Error', label: 'Помилка' },
            ],
            inputProps: {
                disabled: true
            }
        },
        info: {
            label: 'Додаткова інформація',
            type: 'textarea',
            inputProps: {
                disabled: true
            }
        },
        createdAt: {
            label: 'Дата та час',
            type: 'datetime',
            inputProps: {
                disabled: true
            }
        },
        createdBy: {
            label: 'Ким створено',
            type: 'select',
            resource: 'Company.User',
            inputProps: {
                disabled: true
            }
        }
    },
    restUri: {
        get: '/core_api/sale/sales/export_bas',
        getCollection: '/core_api/sale/sales/{entity_id}/export_bas'
    },
    accessFn: mode => mode === 'read' ? true : false
}