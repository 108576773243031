import { BsSend } from "react-icons/bs";
import { useState, useSyncExternalStore } from 'react';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { useShowSuccessNotificationTask } from '@/Ship/Tasks/ShowSuccessNotificationTask';
import { UserStore } from "@/Core/Stores/UserStore";
import { toJS } from "mobx";

export const useSendToBasButton = ({ crudContext  }) => {

    const [ loading, setLoading ] = useState(false);

    const restClient = ServiceStore.get('restClient');

    const showErrorNotificationTask = useShowErrorNotificationTask();
    const showSuccessNotificationTask = useShowSuccessNotificationTask();

    const sendToBasButtonObject = {
        label: 'Відправити в 1С',
        icon: <BsSend />,
        type: 'primary',
        loading,
        onClick: () => {
            setLoading( true );
            restClient.post(
                '/core_api/purchase/purchases/sending_bas',
                { json: { entity: crudContext.formContext.values['@id'] }}
            )
            .getResponse()
            .then( (response) => {
                showSuccessNotificationTask('Успіх', 'Закупівлю успішно відправлено на обробку.');
            })
            .catch( async error => {

                const response = await error.response.json();

                if( response && response['@type'] === 'hydra:Error' ) {
                    showErrorNotificationTask('Помилка', response['hydra:description']);
                }
            })
            .finally(() => {
                setLoading( false );
            })
        },
        depFn: ({ crudContext }) => {

            if( !crudContext.formContext.values?.id )
                return false;

            // if( crudContext.formContext.values.status.code !== 'awaiting_confirmation' )
            //     return false;

            if( crudContext.formContext.values.responsible?.id === UserStore.user?.id )
                return true;

            if( [ 'ROLE_ADMIN', 'ROLE_SUPER_USER' ].some( role => UserStore.user?.roles?.includes( role ) ) )
                return true;

            return false;
        }
    };

    return { sendToBasButtonObject };
}