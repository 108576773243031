
import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { toJS } from "mobx";
import { PurchaseInvoiceFormTemplate } from "../Templates/PurchaseInvoiceFormTemplate";

export const PurchaseInvoiceCrud = () => {
    
    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Purchase.PurchaseInvoice'
        isFilter={false}
        form={{
            initialValues: {
                purchase: toJS(crudContext.formContext.values)
            },
            template: PurchaseInvoiceFormTemplate
        }}
        table={{
            showColumns: [
                'documentNumber', 'date', 'type', 'status', 
                'amountUAH', 'paidAmountUAH', 'tonnage', 'basCode'
            ]
        }}
        drawer={{
            width: 800
        }}
    />
}