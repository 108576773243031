import { UserStore } from "@/Core/Stores/UserStore";

export const TokenResource = {
    title: 'Токени доступу',
    fields: {
        user: {
            label: 'Користувач',
            type: 'select',
            resource: 'Company.User',
            isRequired: true
        },
        expiresAt: {
            label: 'Дата та час закінчення дії',
            type: 'datetime',
            isRequired: true
        },
        token: {
            label: 'Токен',
            type: 'textarea',
            inputProps: {
                disabled: true
            }
        }
    },
    accessFn: (mode) => ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => UserStore.user?.roles.includes(value)) && ['read', 'create', 'delete'].includes(mode) ? true : false,
    restUri: '/core_api/settings/tokens'
}