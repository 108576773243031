import { Button, Input, List, Modal, Space, Tag } from "antd"
import { useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { ResourceCorrespondenceConfig } from '@/Ship/Configs/ResourceCorrespondenceConfig';
import { ReplaceBBCodeTask } from '@/Ship/Tasks/ReplaceBBCodeTask';
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";
import { GetResourceTask } from '@/Core/Tasks/Resources/GetResourceTask';
import { CiSearch } from "react-icons/ci";
import { observer } from "mobx-react-lite";

import './Search.css';

let debounceTimeoutId = null;

const debounce = ( func, delay ) => {
    clearTimeout(debounceTimeoutId);

    debounceTimeoutId = setTimeout(() => {
        func();
    }, delay);
}

export const Search = () => {
    
    const [isShowModal, setIsShowModal] = useState(false);
    const { t } = useTranslation();
    const restClient = ServiceStore.get('restClient');
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const defaultLayoutContext = useDefaultLayoutContext();
    const inputRef = useRef(null);

    const createClickHandler = (type, id) => {
        defaultLayoutContext.openResource({ 
            resourceCode: ResourceCorrespondenceConfig[type], 
            resourceId: id
        });
    };

    const handleClick = (e) => {
        
        let element = e.target.tagName === 'MARK' ? e.target.parentElement : e.target;
        
        if( element.tagName != 'A' )
            element = element.parentElement;
        
        e.preventDefault();
        if (element.tagName === 'A' && element.hasAttribute('data-type') && element.hasAttribute('data-id')) {
            const type = element.getAttribute('data-type');
            const id = element.getAttribute('data-id');
            createClickHandler(type, id);
        }
    };

    const IconObserver = observer(() => {        
        return <div className="wl-search-bar-wrapper">
            {defaultLayoutContext.isCollapsedFirstBar && <a href="#" onClick={() => setIsShowModal(true)} style={{display: 'flex', justifyContent: 'center'}}><BsSearch style={{fontSize: 24, color: '#010101'}} /></a>}
            {!defaultLayoutContext.isCollapsedFirstBar && <Space.Compact><Input placeholder={'AI пошук...'} onClick={() => setIsShowModal(true)} readOnly /><Button type='primary' onClick={() => setIsShowModal(true)}><CiSearch style={{ fontSize: 22}}/></Button></Space.Compact>}
        </div>
    });

    return <>
        <IconObserver />
        <Modal 
            className="wl-searchbar-modal"
            open={isShowModal} 
            destroyOnClose={true} 
            onCancel={() => {
                setIsShowModal(false);
                setData([]);
            }}
            centered={true}
            styles={{ mask: { backgroundColor: 'rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(6px)' } }}
            style={{height: '100%'}}
            width={'100%'}
            footer={null}
            afterOpenChange={(open) => {
                if( open ) 
                    inputRef.current.focus({
                        cursor: 'start',
                    });
            }}
        >
            <div className="input-bar">
                <Input ref={inputRef} placeholder={t("What are you looking for?", { ns: 'Core' })} onChange={ e => {

                    debounce(() => {
                        const queryString = typeof e.target.value === 'string' ? e.target.value.trim() : '';

                        if( !!queryString ) {
                            setLoading(true);

                            restClient
                                .get(`/core_api/system/search?q=${queryString}`)
                                .getResponse()
                                .then( response => {
                                    console.log({ response });
                                    
                                    let d = [];
                                    if( Array.isArray(response) )
                                        
                                        response.map( item => {

                                            if( ResourceCorrespondenceConfig[item.collectionName] == undefined )
                                                return;

                                            if( !Array.isArray(item.hits) || item.hits.length === 0 )
                                                return;

                                            const resourceCode = ResourceCorrespondenceConfig[item.collectionName];
                                            const resource = GetResourceTask(resourceCode);
                                            
                                            let dataItem = {
                                                title: resource.title,
                                                code: resourceCode,
                                                items: []
                                            };

                                            item.hits.map( hit => dataItem.items.push(
                                                <div 
                                                    dangerouslySetInnerHTML={{ __html: ReplaceBBCodeTask(hit) }} 
                                                    onClick={handleClick}
                                                />
                                            ));

                                            d.push(dataItem);
                                        });
                                    
                                    if( d.length === 0 )
                                        d.push(false);

                                    setData(d);
                                })
                                .catch( () => {
                                    setData([false]);
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }
                        else
                            setData([]);
                    }, 500);
                    
                }}/>
            </div>
            {(data.length || loading) > 0 && <List
                className="wl-search-result"
                loading={loading}
                size="small"
                header={false}
                footer={false}
                dataSource={data}
                renderItem={(item) => <List.Item>
                    {item === false ? 'Нічого не знайдено...' : <>
                        <div>
                            <h3>{item.title}</h3>
                            <div className='wl-result-item'>{item.items.map( item => <Tag style={{ padding: '5px 10px', fontSize: 15, marginBottom: 15 }}>{item}</Tag>)}</div>
                        </div>
                    </>}
                </List.Item>}
            />}
        </Modal>
    </>
}