import { SupplierFormTemplate } from '../Templates/SupplierFormTemplate';
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const SupplierResource = {
    title: 'Постачальники',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        fullName: {
            label: 'Повна назва'
        },
        edrpou: {
            label: 'Код ЄДРПОУ'
        },
        ipn: {
            label: 'ІПН'
        },
        vat: {
            label: 'VAT'
        },
        country: {
            label: 'Країна',
            type: 'select',
            resource: 'Setting.ListCountry'
        },
        email: {
            label: 'Пошта'
        },
        phones: {
            label: 'Телефони',
            isMultiple: true
        },
        otherContact: {
            label: 'Інші контактні дані',
            type: 'textarea'
        },
        director: {
            label: 'Директор'
        },
        onBasis: {
            label: 'Директор діє на підставі'
        },
        address: {
            label: 'Адреса'
        },
        iban: {
            label: 'Рахунок IBAN'
        },
        bank: {
            label: 'Банк рахунку'
        },
        comment: {
            label: 'Коментар',
            type: 'textarea'
        },
        isNonResident: {
            label: 'Нерезидент',
            type: 'switch'
        }
    },
    drawer: {
        width: 800
    },
    form: {
        template: SupplierFormTemplate
    },
    restUri: '/core_api/purchase/suppliers',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;
        
        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER', 'ROLE_ACCOUNTANT', 'ROLE_OFFICE_MANAGER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
}
