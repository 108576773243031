import { useCrudResourceContext } from "../CrudResourceContextProvider";
import { App } from "antd";
import { Form, FormField } from "@/Core/UI/Form";
import { DeepCloneTask } from "@/Core/Tasks/Utils/DeepCloneTask";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import dayjs from "dayjs";
import { MercureHandler } from './MercureHandler';
import { useTranslation } from 'react-i18next';

export const FormTemplate = () => {

    const { notification } = App.useApp();
    const crudContext = useCrudResourceContext();
    const formContext = crudContext.formContext;
    const resourceClient = ServiceStore.get('resourceClient');
    const { t } = useTranslation();

    const errorHandler = response => {
        if( response && Array.isArray(response.violations) ) {
            let errors = {};
    
            response.violations.map( field => {
                errors[ field.propertyPath ] = field.message ?? field.title;
            });
    
            formContext.setErrors(errors);
    
            notification.error({
                duration: 3,
                message: t("Data entry error.", { ns: 'Core' }),
                placement: 'bottomRight'
            });
        }
        else if( response && response['hydra:description'] !== undefined )
            notification.error({
                duration: 3,
                message: t("Error", { ns: 'Core' }),
                description: response['hydra:description'],
                placement: 'bottomRight'
            });
    };
    
    crudContext.formContext.addEventHandlers('onSubmit', {
        crudResourceFormSubmit: async ({ values }) => {

            formContext.setIsLoading(true);

            formContext.eventManager.run('onBeforeTransformSendSaveRequest', { values, formContext });

            Object.keys(values).map( key => {
                let value = values[key];

                if( Array.isArray(value) ) {
                    value = value.filter( arrayValue => arrayValue !== null && arrayValue !== undefined && arrayValue !== '' );
                }

                let field = crudContext.formContext.fields.get(key);

                if( !field ) 
                    return;

                // TODO: !!!
                if( Array.isArray(value) && field.isMultiple == false && field.inputProps?.mode !== 'multiple' ) {
                    value = value[0];
                }

                if( value === undefined )
                    value = null;

                if( !Array.isArray(value) && dayjs.isDayjs(value) )
                    value = value.format();

                if( Array.isArray(value) )
                    value = value.map( v => {
                        
                        if( dayjs.isDayjs(v) )
                            return v.format();
                        if( typeof v === 'object' && typeof field.props?.responseValue === 'function')
                            return field.props.responseValue(v);
                        else if( typeof v === 'object' && v['@id'] !== undefined )
                            return v['@id'];

                        return v;
                    });

                // TODO: !!!
                if( typeof value === 'object' && value !== null && typeof field.props?.responseValue === 'function')
                    return field.props.responseValue(value);
                else if( typeof value === 'object' && value !== null && value['@id'] !== undefined )
                    value = value['@id'];
                
                values[key] = value;
            });
            
            delete values.id;
            delete values['@id'];

            const result = await formContext.eventManager.runAsync('onBeforeSendSaveRequest', { values, formContext });

            // Need to stop
            if( Array.isArray(result) && result.filter( el => el === false).length > 0 ) {
                formContext.setIsLoading(false);
                return;
            }

            if( formContext.values?.id ) {

                resourceClient
                    .patch(crudContext.resource, { json: values }, {
                        restUriReplaceParams: { id: formContext.values.id }
                    })
                    .getResponse()
                    .then( response => {

                        if( response?.title && response.title === 'Validation Failed' ) {
                            errorHandler( response );
                            formContext.setIsLoading(false);
                            return;
                        }

                        notification.success({
                            duration: 3,
                            message: t("Save result", { ns: 'Core' }),
                            description: t("Data successfully saved", { ns: 'Core' }),
                            placement: 'bottomRight'
                        });

                        formContext.eventManager.run('onAfterSendSaveRequest', { response, values, formContext, crudContext });

                        crudContext.formContext.setInitialValues(response);
                        
                        if( crudContext.isLiveCrud )
                            MercureHandler({ crudContext, response, loadData: crudContext.listContext.actions.reload });
                        else if( crudContext.listContext ) {
                            crudContext.listContext.actions.reload();
                        }

                        formContext.setIsLoading(false);
                        
                        formContext.eventManager.run('onAfterSuccessSubmit', { formContext, crudContext });
                    })
                    .catch( async (error) => {

                        let response = error?.response;

                        if( response ) {
                            response = await response?.json();
                            errorHandler( response );
                        }

                        formContext.setIsLoading(false);
                    });
            }
            else {

                formContext.eventManager.run('onBeforeCreateRequestSend', { values });

                resourceClient
                    .post(crudContext.resource, { json: values })
                    .getResponse()
                    .then( response => {

                        if( response?.title && response.title === 'Validation Failed' ) {
                            errorHandler( response );
                            formContext.setIsLoading(false);
                            return;
                        }

                        notification.success({
                            duration: 3,
                            message: t("Save result", { ns: 'Core' }),
                            description: t("Data successfully saved", { ns: 'Core' }),
                            placement: 'bottomRight'
                        });
                        formContext.eventManager.run('onAfterSendSaveRequest', { response, values, formContext });
                        crudContext.formContext.setInitialValues(response);

                        if( crudContext.isLiveCrud )
                            MercureHandler({ crudContext, response, loadData: crudContext.listContext.actions.reload });
                        else if( crudContext.listContext )
                            crudContext.listContext.actions.reload();

                        formContext.setIsLoading(false);
                        formContext.eventManager.run('onAfterSuccessSubmit', { formContext, crudContext });
                    })
                    .catch( async (error) => {
                        
                        let response = error.response;
                        response = await response?.json();
                        errorHandler( response );
                        formContext.setIsLoading(false);
                    });
            }
        }
    });

    let template = formContext?.template;
    
    if( !template && crudContext.resource.template ) {
        template = crudContext.resource.template
    }

    if( !template && crudContext.resource?.form?.template ) {
        template = crudContext.resource.form.template
    }

    if( typeof template === 'function' )
        template = template({ crudContext, formContext });

    if( !template ) {

        const fields = DeepCloneTask( crudContext.resource?.fields ?? {} );

        template =  Object.keys(fields).map( name => {   
            if( name == 'id' ) return;
            let field = fields[name];
            return <FormField key={name} name={name} {...field} />;
        });

        template = <Form formContext={formContext}>{template}</Form>;
    }

    return <>{template}</>;
};