import { CrudResource } from "@/Core/UI/CrudResource";
import { TbLayoutKanban } from "react-icons/tb";
import { PurchaseKanbanMode } from './PurchaseKanbanMode';
import { UserStore } from "@/Core/Stores/UserStore";
import { Tag } from 'antd';
import './PurchaseCrud.css';

export const PurchaseCrud = ({ ...props }) => {

    props.form ??= {};
    props.form.initialValues = {
        responsible: UserStore.user
    };

    return <CrudResource 
        resource='Purchase.Purchase'
        filter={{
            includeFields: [
                'status', 'contractNumber', 'supplier',
                'organization', 'isSigned', 'isDocumentsReceived', 'isDocumentsSended',
                'responsible', 'dateContract', 'deadline',
                'typeBonusPayment'
            ]
        }}
        modeItems={{
            kanban: {
                title: 'Канбан',
                icon: TbLayoutKanban,
                component: PurchaseKanbanMode
            }
        }}
        table={{
            showColumns: [
                'status', 'contractNumber', 'supplier',
                'organization', 'isSigned', 'isDocumentsReceived',
                'isDocumentsSended', 'responsible', 'dateContract', 
                'deadline', 'product', 'quantity', 'optionPercentage',
                'price', 'priceCurrency', 'contractAmount', 'contractAmountCurrency', 
                'amountUAH', 'termDelivery', 'termPayment'
            ],
            eventHandlers: {
                onBuildColumn: {
                    transformStatus: ({ column }) => {
                        if( column.key === 'status' ) {
                            column.render = ( status, item ) => <Tag color={item._original.status.color}>{status}</Tag>
                        }

                        if( column.key === 'isDocumentsReceived' || column.key === 'isDocumentsSended' ) {
                            if( column?.title?.props?.children ) {
                                const title = column.title.props.children;
                                column.title = title;
                            }
                        }
                    }
                }
            },
            rowClassName: (record) => record.typeBonusPayment === 'full_paid' ? 'full-paid' : '',
        }}
        {...props}
    />
}