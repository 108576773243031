import { UserStore } from "@/Core/Stores/UserStore";
import { toJS } from 'mobx';

export const PurchaseInvoiceResource = {
    title: 'Рахунки',
    fields: {
        documentNumber: {
            label: 'Номер документу',
            isRequired: true
        },
        date: {
            label: 'Дата',
            type: 'date',
            isRequired: true
        },
        type: {
            label: 'Тип документу',
            type: 'select',
            options: [
                { value: 'invoice', label: 'Рахунок' },
                { value: 'realization', label: 'Реалізація' },
            ]
        },
        status: {
            label: 'Статус',
            type: 'select',
            options: [
                { value: 'invoiced', label: 'Виставлений' },
                { value: 'paided', label: 'Сплачений' },
            ]
        },
        amountUAH: {
            label: 'Сума в грн',
            type: 'number'
        },
        paidAmountUAH: {
            label: 'Сплачена сума в грн',
            type: 'number'
        },
        tonnage: {
            label: 'Тоннаж',
            type: 'number'
        },
        basCode: {
            label: 'Код 1С'
        },
        purchase: {
            label: 'Закупівля',
            type: 'select',
            resource: 'Purchase.Purchase',
            isRequired: true
        },
        externalCode: {
            label: 'Зовнішній код'
        }
    },
    restUri: '/core_api/purchase/invoices',
    accessFn: ( mode, item ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;
        
        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};