import { Table as TableAntd } from "antd";
import { useListContext } from '@/Core/UI/List/ListContextProvider';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { observer, useObserver } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { MergeConfigAndPropsTask } from '@/Core/Tasks/Utils/MergeConfigAndPropsTask';
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import { useCrudResourceContext } from '../CrudResource/CrudResourceContextProvider';
import { useEffect, useRef, useState } from 'react';
import { useDefaultLayoutContext } from '@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider';
import { DeepCloneTask } from '@/Core/Tasks/Utils/DeepCloneTask';
import { OverlayScrollbars } from '@/Core/UI/OverlayScrollbars';
import './Table.css';

export const Table = observer(tableProps => {

    const tableRef = useRef(null);
    const listContext = useListContext();
    const tableConfig = ConfigStore.get('TableConfig');
    const crudContext = useCrudResourceContext();
    const defaultLayoutContext = useDefaultLayoutContext();
    let props = MergeConfigAndPropsTask({ tableConfig, tableProps });

    if( typeof props?.rowSelection?.selectedRowKeys === 'function' )
        props.rowSelection.selectedRowKeys = props.rowSelection.selectedRowKeys();

    const UI = new UIBaseStore(props);
    
    const [tableHeight, setTableHeight] = useState(400);

    const calculateTableHeight = () => {
        if (tableRef.current) {
            const windowHeight = window.innerHeight;
            const tableTop = tableRef.current.getBoundingClientRect().top;
            
            let newHeight = windowHeight - tableTop - 140;

            if( typeof props?.footer === 'function' )
                newHeight -= 60;

            setTableHeight(Math.max(newHeight, 300));
        }
    };

    console.log('Table RENDER');
    

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);
        return () => window.removeEventListener('resize', calculateTableHeight);
    }, []);

    UI.eventManager.run('onInit', { context: UI, crudContext, listContext });
    UI.eventManager.run('onBuildProps', { props: UI.props, crudContext, listContext });

    if (UI.props.loading === undefined && listContext)
        UI.mergeProps({ loading: listContext.loader.isLoading });

    // Pagination logic
    if (UI.props?.pagination === undefined && typeof listContext?.pagination === 'object') {
        UI.mergeProps({ pagination: {...listContext.pagination, showSizeChanger: false } });
    } else if (typeof UI.props?.pagination === 'object' && typeof listContext?.pagination === 'object') {
        UI.mergeProps({ pagination: {...UI.props.pagination, ...listContext.pagination, showSizeChanger: false} });
    }

    // Columns logic
    if (
        UI.props.columns === undefined
        && listContext !== undefined
        && typeof listContext?.fields === 'object'
        && Object.keys(listContext.fields).length > 0
    ) {
        let columns = Object.keys(listContext.fields)
            .filter(name => !Array.isArray(UI.props.showColumns) || UI.props.showColumns.includes(name))
            .map(name => {
                const field = listContext.fields[name];
                return { 
                    title: <div style={{ whiteSpace: 'nowrap' }}>{field?.label ?? ''}</div>,
                    dataIndex: name,
                    key: name
                };
            });
        
        UI.mergeProps({ columns: toJS(columns) });
    }

    // Column processing
    if (Array.isArray(UI.props.columns)) {
        UI.mergeProps({ columns: toJS(UI.props.columns) });
        
        let columns = UI.props.columns;
        UI.eventManager.run('onBuildColumns', { columns, tableContext: UI, crudContext });

        if( UI.props.isDefaultScroll === true )
            columns.forEach( column => {
                
                if( !column?.width )
                    column.width = 150

                if( ['_actions', 'id'].includes(column.key) )
                    column.width = 70;
            });

        if (UI.eventManager.hasHandlers('onBuildColumn'))
            columns.forEach(column => UI.eventManager.run('onBuildColumn', { column, tableContext: UI, crudContext }));

        if (Array.isArray(UI.props.showColumns)) {
            if (UI.props.settings?.isActionsColumnHide !== true)
                UI.props.showColumns.unshift('_actions');

            UI.props.showColumns.push('_original');

            columns = columns
                .filter(column => UI.props.showColumns.includes(column.key))
                .map(column => {
                    if (typeof UI.props.tableColumnProps?.[column.key] === 'object')
                        return { ...column, ...UI.props.tableColumnProps[column.key] };
                    return column;
                });

            const orderMap = UI.props.showColumns.reduce((acc, key, index) => {
                acc[key] = index;
                return acc;
            }, {});
                
            columns.sort((a, b) => orderMap[a.key] - orderMap[b.key]);
        }
        
        UI.mergeProps({ columns });
    }

    // Data source processing
    let dataSource = Array.isArray(listContext?.loader?.items) 
        ? toJS(listContext.loader.items) 
        : (Array.isArray(UI.props.dataSource) ? UI.props.dataSource : []);

    UI.mergeProps({ dataSource });

    if (UI.props.dataSource.length > 0) {
        UI.eventManager.run('onBuildDataSourceItems', { dataSource: UI.props.dataSource, tableContext: UI, crudContext });

        if (UI.eventManager.hasHandlers('onBuildDataSourceItem')) {
            UI.props.dataSource.forEach(item => {
                item._original = DeepCloneTask(item);
                UI.eventManager.run('onBuildDataSourceItem', { item, tableContext: UI, crudContext, defaultLayoutContext });
            });
        }
    }

    if( UI.props.isDefaultScroll === true ) {

        let totalWidth = 0;
        UI.props.columns.forEach( column => {
            totalWidth += column.width;
        });
        
        // Set up virtual scrolling
        const scroll = { y: tableHeight, ...UI.props.scroll };
        const virtual = true;
    
        return (
            <div ref={tableRef} style={{ width: '100%', height: '100%' }} className='wl-table'>
                <TableAntd 
                    {...UI.props}
                    scroll={scroll}
                    virtual={virtual}
                />
            </div>
        );
    }

    const components = {
        table: (props) => (
            <OverlayScrollbars>
              <table {...props} />
            </OverlayScrollbars>
        ),
        ...(typeof UI.props?.components === 'object' ? UI.props.components : {})
    };

    UI.mergeProps({ components });

    return <div ref={tableRef} style={{ width: '100%', height: '100%' }}>
        <TableAntd {...UI.props} />
    </div>;

    
});