export const ListProductCharacteristicResource = {
    title: 'Характеристики номенклатури',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        products: {
            label: 'Продукти',
            // isRequired: true,
            isMultiple: true,
            type: 'select',
            resource: 'Company.Product'
        },
        code: {
            label: 'Код',
            isRequired: true
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        },
        externalCode: {
            label: 'Зовнішній код'
        },
    },
    restUri: '/core_api/settings/product/characteristics'
}