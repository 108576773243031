import { action, computed, makeObservable, observable } from "mobx";

import { UserOptionStore } from '@/Core/Stores/UserOptionStore';
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';

export class DefaultLayoutStore extends UIBaseStore {

    userOptions = null;

    customStyle = {};

    firstBarWidthExpanded = 245;
    firstBarWidthCollapsed = 72;
    secondBarWidth = 56;

    headerContent = undefined;
    firstBarContent = undefined;
    secondBarContent = undefined;
    mobileBarContent = undefined;

    resourceCode = null;
    resourceId = null;
    resourceContext = null;
    crudResourceProps = {};
    
    isMobileMode = true;
    isOpenMobileFirstBar = false;
    mobileModeWidth = 670;

    constructor({
        isShowHeader,
        isShowFirstBar,
        isShowSecondBar,
        firstBarWidthExpanded,
        firstBarWidthCollapsed,
        secondBarWidth,
        isCollapsedFirstBar,
        themeMode,
        customStyle,
        headerContent,
        firstBarContent,
        secondBarContent,
        mobileBarContent,
        layoutKey = 'wlDefaultLayout',
        ...props
    }) {

        super(props);

        this.userOptions = new UserOptionStore({ key: layoutKey });

        if( headerContent !== undefined ) this.headerContent = headerContent;
        if( firstBarContent !== undefined ) this.firstBarContent = firstBarContent;
        if( secondBarContent !== undefined ) this.secondBarContent = secondBarContent;
        if( mobileBarContent !== undefined ) this.mobileBarContent = mobileBarContent;

        if( typeof firstBarWidthExpanded === 'number' ) this.firstBarWidthExpanded = firstBarWidthExpanded;
        if( typeof firstBarWidthCollapsed === 'number' ) this.firstBarWidthCollapsed = firstBarWidthCollapsed;
        if( typeof secondBarWidth === 'number' ) this.secondBarWidth = secondBarWidth;

        if( typeof isShowHeader === 'boolean' ) this.setIsShowHeader( isShowHeader );
        if( typeof isShowFirstBar === 'boolean' ) this.setIsShowFirstBar( isShowFirstBar );
        if( typeof isShowSecondBar === 'boolean' ) this.setIsShowSecondBar( isShowSecondBar );
        if( typeof isCollapsedFirstBar === 'boolean' ) this.setIsCollapsedFirstBar( isCollapsedFirstBar );

        if( typeof themeMode === 'string' && ['light', 'dark'].includes(themeMode) )
            this.setThemeMode( themeMode );

        if( typeof customStyle === 'object' ) this.customStyle = customStyle;

        makeObservable( this, {
            secondBarWidth: observable,
            resourceCode: observable,
            resourceId: observable,
            isMobileMode: observable,
            isOpenMobileFirstBar: observable,
            firstBarWidth: computed,
            isShowHeader: computed,
            isShowFirstBar: computed,
            isShowSecondBar: computed,
            isCollapsedFirstBar: computed,
            themeMode: computed,
            setIsShowHeader: action,
            setIsShowFirstBar: action,
            setIsShowSecondBar: action,
            setThemeMode: action,
            setIsCollapsedFirstBar: action,
            setIsMobileMode: action,
            openResource: action,
            setIsOpenMobileFirstBar: action
        });
    }

    get isShowHeader() {
        const isShowHeader = this.userOptions.get('isShowHeader');
        return isShowHeader === true ? true : false;
    }

    get isShowFirstBar() {
        const isShowFirstBar = this.userOptions.get('isShowFirstBar');
        return isShowFirstBar === false ? false : true;
    }

    get isShowSecondBar() {
        const isShowSecondBar = this.userOptions.get('isShowSecondBar');
        return isShowSecondBar === true ? true : false;
    }

    get isCollapsedFirstBar() {
        const isCollapsedFirstBar = this.userOptions.get('isCollapsedFirstBar');
        return isCollapsedFirstBar === true ? true : false;
    }

    get firstBarWidth() {
        return this.isCollapsedFirstBar ? this.firstBarWidthCollapsed : this.firstBarWidthExpanded;
    }

    get themeMode() {
        return this.userOptions.get('themeMode') ?? 'light';
    }

    setIsOpenMobileFirstBar( isOpenMobileFirstBar ) {
        this.isOpenMobileFirstBar = isOpenMobileFirstBar;
    }

    setIsMobileMode( mode ) {
        this.isMobileMode = mode === true ? true : false;
    }

    setIsShowHeader( isShowHeader ) {
        this.userOptions.set('isShowHeader', isShowHeader);
    }

    setIsShowFirstBar( isShowFirstBar ) {
        this.userOptions.set('isShowFirstBar', isShowFirstBar);
    }

    setIsShowSecondBar( isShowSecondBar ) {
        this.userOptions.set('isShowSecondBar', isShowSecondBar);
    }

    setIsShowMobileBar( isShowMobileBar ) {
        this.userOptions.set('isShowMobileBar', isShowMobileBar);
    }

    setThemeMode( themeMode ) {
        this.themeMode = themeMode;
    }

    setIsCollapsedFirstBar( isCollapsedFirstBar ) {
        this.userOptions.set('isCollapsedFirstBar', isCollapsedFirstBar);
    }

    setResourceContext( resourceContext ) {
        this.resourceContext = resourceContext;
    }

    openResource({
        resourceCode, 
        resourceId = 0, 
        mode = 'view', 
        onClose = (() => {}),
        props = {},
        crudResourceProps = {}
    }) {

        crudResourceProps.drawer ??= {};

        // if( crudResourceProps.drawer?.zIndex === undefined )
        //     crudResourceProps.drawer.zIndex = 20000;

        this.resourceCode = resourceCode;
        this.resourceId = resourceId;
        this.crudResourceProps = crudResourceProps;

        setTimeout(() => {

            if( !this.resourceContext )
                return;

            this.resourceContext.drawerContext.addEventHandlers( 'onClose', {
                drawerContextOnClose: onClose,
                clearResource: () => this.clearResource()
            } );

            if( resourceId === 0 ) {
                this.resourceContext.actions.create( props );
                return;
            }

            if( mode === 'view' )
                this.resourceContext.actions.view({ id: resourceId });

            if( mode === 'edit' )
                this.resourceContext.actions.edit({ id: resourceId });

        }, 300);
    }

    clearResource() {
        this.resourceCode = null;
        this.resourceId = null;
        this.resourceContext = null;
    }
}