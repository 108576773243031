import { toJS } from "mobx";
import { UserStore } from "@/Core/Stores/UserStore";

export const ProductResource = {
    title: 'Продукти',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        characteristics: {
            label: 'Характеристики',
            // isRequired: true,
            isMultiple: true,
            type: 'select',
            resource: 'Setting.ListProductCharacteristic'
        },
        color: {
            label: 'Колір',
            type: 'color'
        },
        code: {
            label: 'Код',
            isRequired: true
        },
        description: {
            label: 'Опис',
            type: 'textarea'
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        }
    },
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    },
    restUri: '/core_api/settings/products'
};