import { TransformPriceCurrencyViewFieldTask } from '@/Ship/Tasks/TransformPriceCurrencyViewFieldTask';
import { DefaultValueConfig } from '@/Ship/Configs/DefaultValueConfig';
import { useConfirmedLogisticianStatusButton } from '../Hooks/useConfirmedLogisticianStatusButton';
import { useExecutionConfirmedStatusButton } from '../Hooks/useExecutionConfirmedStatusButton';
import { useSendToApproveButtonHook } from '../Hooks/useSendToApproveButtonHook';
import { useQuantityUnloadingButtonHook } from '../Hooks/useQuantityUnloadingButtonHook';
import { SaleFormTemplate } from "../Templates/SaleFormTemplate";
import { toJS } from "mobx";
import { UserStore } from "@/Core/Stores/UserStore";
import { useSendToBasButton } from '../Hooks/useSendToBasButton';

export const SaleResource = {
    title: 'Продажі',
    fields: {
        status: {
            label: 'Статус',
            type: 'select',
            resource: 'Setting.ListSaleStatus'
        },
        contractNumber: {
            label: 'Номер договору',
            isRequired: true
        },
        organization: {
            label: 'Організація',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListOrganization'
        },
        isDocumentsSended: {
            label: 'Оригінали документів відправлено',
            type: 'switch'
        },
        isDocumentsReceived: {
            label: 'Оригінали документів отримано',
            type: 'switch'
        },
        responsible: {
            label: 'Відповідальний',
            type: 'select',
            resource: 'Company.User'
        },
        dateContract: {
            label: 'Дата договору',
            type: 'date',
            isRequired: true
        },
        dateDelivery: {
            label: 'Термін поставки',
            type: 'date',
            isRequired: true
        },
        client: {
            label: 'Клієнт',
            isRequired: true,
            type: 'select',
            resource: 'Sale.Client',
            isLink: true
        },
        product: {
            label: 'Номенклатура',
            isRequired: true,
            type: 'select',
            resource: 'Company.Product'
        },
        quality: {
            label: 'Якість',
            isRequired: true,
        },
        quantity: {
            label: 'Кількість',
            type: 'number'
        },
        optionPercentage: {
            label: 'Відсоток опціону',
            inputProps: {
                disabled: true
            }
        },
        price: {
            label: 'Ціна',
            type: 'number',
            view: ( value, formContext ) => TransformPriceCurrencyViewFieldTask({
                value,
                values: toJS( formContext.initialValues ),
                costCurrencyFieldName: 'priceCurrency'
            })
        },
        priceCurrency: {
            label: 'Валюта ціни',
            type: 'select',
            resource: 'Setting.ListCurrency',
            defaultValue: DefaultValueConfig.currencyDefaultValue
        },
        contractAmount: {
            label: 'Загальна сума контракту',
            type: 'number',
            view: ( value, formContext ) => TransformPriceCurrencyViewFieldTask({
                value,
                values: toJS( formContext.initialValues ),
                costCurrencyFieldName: 'contractAmountCurrency'
            }),
            inputProps: {
                disabled: true
            }
        },
        contractAmountCurrency: {
            label: 'Валюта загальної суми контракту',
            type: 'select',
            resource: 'Setting.ListCurrency',
            defaultValue: DefaultValueConfig.currencyDefaultValue,
            inputProps: {
                disabled: true
            }
        },
        amountUAH: {
            label: 'Загальна сума контракту в грн',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        invoiceAmountUAH: {
            label: 'Сума рахунків в грн',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        totalQuantity: {
            label: 'Загальна кількість',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        percentageCompletion: {
            label: 'Відсоток виконання',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        remainder: {
            label: 'Залишок',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        termDelivery: {
            label: 'Умови поставки',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListTermDelivery'

        },
        deliveryPlace: {
            label: 'Місце поставки',
            isRequired: true
        },
        documentSigningType: {
            label: 'Тип підписання документів',
            type: 'select',
            resource: 'Setting.ListDocumentSigningType'
        },
        comment: {
            label: 'Коментар',
            type: 'textarea'
        },
        isConfirmedLogistician: {
            label: 'Підтверджено логістом',
            type: 'switch',
            inputProps: {
                disabled: true
            }
        },
        commentLogistician: {
            label: 'Коментар логіста',
            type: 'textarea',
            inputProps: {
                disabled: true
            }
        },
        isConfirmedExecution: {
            label: 'Підтверджено Execution',
            type: 'switch',
            inputProps: {
                disabled: true
            }
        },
        commentExecution: {
            label: 'Коментар Execution',
            type: 'textarea',
            inputProps: {
                disabled: true
            }
        },
        reasonLosing: {
            label: 'Причини анулювання',
            type: 'select',
            resource: 'Setting.ListSaleReasonLosing',
            inputProps: {
                disabled: true
            }
        },
        basCode: {
            label: 'Код 1С',
            inputProps: {
                disabled: true
            }
        }
    },
    drawer: {
        width: 1200,
        eventHandlers: {
            onButtons: {
                addButtons: ({ buttons, crudContext, content }) => {
                    
                    const { sendToApproveButtonObject } = useSendToApproveButtonHook({ crudContext });
                    buttons.sendToApprove = sendToApproveButtonObject;

                    const { confirmedLogisticianStatusButton, ConfirmedLogisticianStatusModal } = useConfirmedLogisticianStatusButton(crudContext);

                    buttons.confirmedLogisticianStatusButton = confirmedLogisticianStatusButton;
                    content.push(<ConfirmedLogisticianStatusModal key='ConfirmedLogisticianStatusModal' />);

                    const { executionConfirmedStatusButton, ExecutionConfirmedStatusModal } = useExecutionConfirmedStatusButton(crudContext);

                    buttons.executionConfirmedStatusButton = executionConfirmedStatusButton;
                    content.push(<ExecutionConfirmedStatusModal key='ExecutionConfirmedStatusModal' />);
                    
                    const { quantityUnloadingButtonObject, QuantityUnloadingModal } = useQuantityUnloadingButtonHook({ crudContext });
                    buttons.quantityUnloadingButtonObject = quantityUnloadingButtonObject;
                    content.push(<QuantityUnloadingModal key='QuantityUnloadingModal' />);
                },
                sendToBas: ({ buttons, crudContext, content }) => {
                    const { sendToBasButtonObject } = useSendToBasButton({ buttons, crudContext, content });
                    buttons.sendToBas = sendToBasButtonObject;
                }
            }
        }
    },
    template: SaleFormTemplate,
    restUri: '/core_api/sale/sales',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore.user.roles);

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_SENIOR_MANAGER', 'ROLE_MANAGER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_OFFICE_MANAGER', 'ROLE_SENIOR_MANAGER', 'ROLE_MANAGER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_SENIOR_MANAGER', 'ROLE_MANAGER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};