import { toJS } from "mobx";
import { Select, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

import { useFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { GetRestUriTask } from "@/Core/Tasks/Resources/GetRestUriTask";
import { GetResourceTask } from "@/Core/Tasks/Resources/GetResourceTask";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import React from 'react';

export const SelectInput = ({ field, index }) => {
    
    const formContext = useFormContext();
    const restClient = ServiceStore.get('restClient');
    const { t } = useTranslation();
    const activeRequestRef = React.useRef(null);

    let {
        filter = {}, 
        searchBy, 
        loadResponse, 
        searchOff = false,
        allowClearOff = false,
        onChange = undefined,
        resource = null,
        responseLabel,
        responseValue,
        onAfterSearchRequest,
        ...inputPropsOther
    } = {...(field?.props ?? {}), ...field.bind({ index: index })};

    if( typeof resource === 'string' )
        resource = GetResourceTask( resource );

    const sendRequest = async params => {

        if( !resource?.restUri )
            return;
        
        if (activeRequestRef.current && !activeRequestRef.current.signal.aborted) {
            activeRequestRef.current.abort();
        }

        activeRequestRef.current = new AbortController(); 

        field.setState( 'isLoading', true );

        let items = await restClient.get( GetRestUriTask(resource.restUri), { searchParams: params, signal: activeRequestRef.current.signal } ).getResponse();

        let opts = [];
        
        if( items && Array.isArray(items['hydra:member']) )
            items['hydra:member'].map(item => {
                const option = {
                    _original: item,
                    value: typeof responseValue === 'function'
                            ? responseValue(item)
                            : field.valueEditTransformer(item),
                    label: typeof responseLabel === 'function' 
                            ? responseLabel(item) 
                            : field.valueViewTransformer(item)
                };
                
                opts.push(option);
            });

        if( typeof onAfterSearchRequest === 'function' ) {
            const optsTransformed = onAfterSearchRequest({ options: opts, field });
            if( Array.isArray(optsTransformed) )
                opts = optsTransformed;
        }

        field.setStates({
            ...field.states,
            options: opts,
            isLoading: false
        });
    }

    const handleSelectChange = ( value, onChange ) => {

        let selectedOption = [];

        const currentOptions = toJS(field.states.options) ?? [];
        const currentValue = toJS(field.formContext.getValue(field.path));

        if( Array.isArray(currentValue) ) {
            currentValue.map( value => {
                const inArray = currentOptions.filter( co => co.value == value['@id'] );
                if( inArray.length === 0 )
                    currentOptions.push({
                        _original: value,
                        value: value['@id']
                    });
            });
        }

        if( Array.isArray( currentOptions ) ) {
            if(Array.isArray(value)) {
                selectedOption = currentOptions.filter((option) => value.includes(option.value));
            } else {
                selectedOption = currentOptions.filter((option) => option.value === value);
            }
        }

        selectedOption = selectedOption.map( option => option._original ?? option );

        if( !Array.isArray(value) ) {
            selectedOption = selectedOption[0];
        }

        if( typeof onChange === 'function' ) {
            let changedValue = undefined;

            if( selectedOption?.label !== undefined && selectedOption?.value !== undefined )
                changedValue = selectedOption.value;
            else if( selectedOption )
                changedValue = selectedOption;
       
            onChange( changedValue );
        }
    };
    
    if( !resource || !formContext ) {

        const onChangeHandler = value => handleSelectChange(value, onChange);
        const onSearch = value => typeof inputPropsOther?.onSearch === 'function' ? inputPropsOther.onSearch( value, field ) : null;
        const stateOptions = toJS(field.states.options) ?? [];

        if( 
            stateOptions.length === 0 
            && Array.isArray(field?.props?.options) 
            && field.props.options.length > 0
        ) {
            field.setState( 'options', field.props.options );
        }

        const SelectObserver = observer(() => {

            let options = toJS(field.states.options) ?? [];

            return <Select 
                {...inputPropsOther} 
                options={options} 
                onChange={onChangeHandler} 
                value={field.value} 
                onSearch={onSearch} 
            />;
        });

        return <SelectObserver />;
    }

    const handleSelectSearch = async newValue => {
        
        if ( !newValue ) 
            return;
        
        if( searchBy )
        {
            if( typeof searchBy == 'string' )
                filter[searchBy] = newValue;
            else if( Array.isArray( searchBy ) )
                searchBy.map( item => filter[`or[${item}]`] = newValue );
        }

        let params = { _search: newValue };

        Object.keys(filter).map(key => {
    
            if(filter[key] !== undefined && (filter[key] === false || filter[key]))
            {
                if(Array.isArray(filter[key]))
                    filter[key].map( ( el, index ) => {
                        params[`${key}[${index}]`] = el;
                    } )
                else
                    params[key] = filter[key];
            }
    
        });

        await sendRequest(params);
    };

    const inputProps = {
        ...inputPropsOther,
        showSearch: !searchOff ? true : false,
        defaultActiveFirstOption: false,
        suffixIcon: null,
        filterOption: false,
        allowClear: !allowClearOff ? true : false,
        onChange: value => handleSelectChange(value, onChange),
        onFocus: async () => {
            
            let params = {};
            Object.keys(filter).map(key => {
                if(filter[key] !== undefined && (filter[key] === false || filter[key])) {
                    if(Array.isArray(filter[key]))
                        filter[key].map( ( el, index ) => {
                            params[`${key}[${index}]`] = el;
                        } )
                    else
                        params[key] = filter[key];
                }
            });
            
            await sendRequest(params); 
        },
        onSearch: !searchOff ? handleSelectSearch : null,
        style: { width: '100%' }
    }
    
    const SelectObserver = observer(() => {

        let options = toJS(field.states.options) ?? [];
        
        if( 
            Array.isArray(options) 
            && options.length === 0 
            && field.value
        ) {
            
            let selectedValue = [];
            const originalInitialValue = field.formContext.getValue( field.path );
            
            if( typeof originalInitialValue === 'object' && !Array.isArray( originalInitialValue ) ) {
                selectedValue.push({
                    _original: toJS(originalInitialValue),
                    label: typeof responseLabel === 'function' ? responseLabel(originalInitialValue) : field.valueViewTransformer(originalInitialValue),
                    value: typeof responseValue === 'function' ? responseValue(originalInitialValue) : field.valueEditTransformer(originalInitialValue)
                });
            } else if( Array.isArray(originalInitialValue) ) {
                selectedValue = originalInitialValue.map( item => {
                    return {
                        _original: item,
                        label: typeof responseLabel === 'function' ? responseLabel(item) : field.valueViewTransformer(item),
                        value: typeof responseValue === 'function' ? responseValue(item) : field.valueEditTransformer(item)
                    };
                });
            }
    
            options = [...selectedValue];
        }

        return <Select 
            {...inputProps}
            value={field.value}
            options={options}
            notFoundContent={
                field.states.isLoading 
                ? <div style={{padding: '5px 10px'}}>
                      <Spin size="small" />
                  </div> 
                : t("Nothing found...", { ns: 'Core' })
            }
        />
    });

    return <SelectObserver />;
};