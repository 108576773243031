import { toJS } from "mobx";
import { TransformPriceCurrencyViewFieldTask } from '@/Ship/Tasks/TransformPriceCurrencyViewFieldTask';
import { DefaultValueConfig } from '@/Ship/Configs/DefaultValueConfig';
import { UserStore } from "@/Core/Stores/UserStore";
import { PurchaseFormTemplate } from '../Templates/PurchaseFormTemplate';
import { useSendToApproveButtonHook } from '../Hooks/useSendToApproveButtonHook';
import { useApproveExecutionButtonHook } from '../Hooks/useApproveExecutionButtonHook';
import { useApproveCalculationButtonHook } from '../Hooks/useApproveCalculationButtonHook';
import { useBonusesPaidButtonHook } from '../Hooks/useBonusesPaidButtonHook';
import { useSendToBasButton } from '../Hooks/useSendToBasButton';

export const PurchaseResource = {
    title: 'Закупівлі',
    fields: {
        status: {
            label: 'Статус',
            type: 'select',
            resource: 'Setting.ListPurchaseStatus'
        },
        contractNumber: {
            label: 'Номер договору',
            isRequired: true
        },
        isSigned: {
            label: 'Підписано',
            type: 'switch'
        },
        isDocumentsReceived: {
            label: 'Оригінали документів отримано',
            type: 'switch'
        },
        isDocumentsSended: {
            label: 'Оригінали документів відправлено',
            type: 'switch'
        },
        responsible: {
            label: 'Відповідальний',
            type: 'select',
            resource: 'Company.User'
        },
        dateContract: {
            label: 'Дата договору',
            isRequired: true,
            type: 'date'
        },
        deadline: {
            label: 'Крайній термін',
            isRequired: true,
            type: 'date'
        },
        supplier: {
            label: 'Постачальник',
            isRequired: true,
            type: 'select',
            resource: 'Purchase.Supplier',
            isLink: true
        },
        organization: {
            label: 'Організація',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListOrganization',
            isLink: true
        },
        product: {
            label: 'Номенклатура',
            isRequired: true,
            type: 'select',
            resource: 'Company.Product',
            isLink: true
        },
        characteristics: {
            label: 'Характеристики номенклатури',
            isMultiple: true,
            type: 'select',
            resource: 'Setting.ListProductCharacteristic'
        },
        quantity: {
            label: 'Кількість',
            type: 'number',
            isRequired: true
        },
        optionPercentage: {
            label: 'Відсоток опціону',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        price: {
            label: 'Ціна',
            type: 'number',
            isRequired: true,
            view: ( value, formContext ) => TransformPriceCurrencyViewFieldTask({
                value,
                values: toJS( formContext.initialValues ),
                costCurrencyFieldName: 'priceCurrency'
            })
        },
        priceCurrency: {
            label: 'Валюта ціни',
            type: 'select',
            resource: 'Setting.ListCurrency',
            defaultValue: DefaultValueConfig.currencyDefaultValue
        },
        contractAmount: {
            label: 'Загальна сума контракту',
            type: 'number',
            inputProps: {
                disabled: true
            },
            view: ( value, formContext ) => TransformPriceCurrencyViewFieldTask({
                value,
                values: toJS( formContext.initialValues ),
                costCurrencyFieldName: 'contractAmountCurrency'
            })
        },
        contractAmountCurrency: {
            label: 'Валюта загальної суми контракту',
            type: 'select',
            resource: 'Setting.ListCurrency',
            inputProps: {
                disabled: true
            },
            defaultValue: DefaultValueConfig.currencyDefaultValue
        },
        amountUAH: {
            label: 'Загальна сума в грн',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        invoiceAmountUAH: {
            label: 'Сума рахунків в грн',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        termDelivery: {
            label: 'Умови поставки',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListTermDelivery'
        },
        termPayment: {
            label: 'Умови оплати',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListTermPayment'
        },
        comment: {
            label: 'Коментар',
            type: 'textarea'
        },
        paidUAH: {
            label: 'Сплачено в грн',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        percentagePayment: {
            label: 'Відсоток оплати',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        completion: {
            label: 'Виконано (т)',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        percentageCompletion: {
            label: 'Відсоток виконання',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        remainder: {
            label: 'Залишок',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        reasonLosing: {
            label: 'Причини анулювання',
            type: 'select',
            inputProps: {
                disabled: true
            },
            resource: 'Setting.ListPurchaseReasonLosing'
        },
        reservedSales: {
            label: 'Зарезервовано продажами',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        salesRemainder: {
            label: 'Вільний залишок',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        isExecutionConfirmed: {
            label: 'Підтверджено виконання',
            type: 'switch',
            inputProps: {
                disabled: true
            }
        },
        isCalculationConfirmed: {
            label: 'Підтверджено розрахунок',
            type: 'switch',
            inputProps: {
                disabled: true
            }
        },
        bonusesPaid: {
            label: 'Виплачено бонусів (т)',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        typeBonusPayment: {
            label: 'Статус виплати бонусів',
            type: 'select',
            options: [
                { label: 'Не виплачені', value: 'not_paid' },
                { label: 'Частково виплачені', value: 'partially_paid' },
                { label: 'Повністю виплачені', value: 'full_paid' }
            ],
            inputProps: {
                disabled: true
            }
        },
        basCode: {
            label: 'Код 1С',
            inputProps: {
                disabled: true
            }
        }
    },
    drawer: {
        width: 1200,
        eventHandlers: {
            onButtons: {
                sendToApproveButton: ({ buttons, crudContext }) => {
                    const { sendToApproveButtonObject } = useSendToApproveButtonHook({ crudContext });
                    buttons.sendToApprove = sendToApproveButtonObject;
                },
                approveExecutionButton: ({ buttons, crudContext }) => {
                    const { approveExecutionButtonObject } = useApproveExecutionButtonHook({ crudContext });
                    buttons.approveExecution = approveExecutionButtonObject;
                },
                approveCalculationButton: ({ buttons, crudContext }) => {
                   const { approveCalculationButtonObject } = useApproveCalculationButtonHook({ crudContext });
                   buttons.approveCalculation = approveCalculationButtonObject;
                },
                bonusesPaidButton: ({ buttons, crudContext, content }) => {
                    const { bonusesPaidButton, BonusesPaidModal } = useBonusesPaidButtonHook({ crudContext });
                    buttons.bonusesPaidButton = bonusesPaidButton;
                    content.push(<BonusesPaidModal key='bonusesPaidButton' />);
                },
                sendToBas: ({ buttons, crudContext, content }) => {
                    const { sendToBasButtonObject } = useSendToBasButton({ buttons, crudContext, content });
                    buttons.sendToBas = sendToBasButtonObject;
                }
            }   
        }
    },
    form: {
        template: PurchaseFormTemplate
    },
    restUri: '/core_api/purchase/purchases',
    accessFn: ( mode, item ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;
        
        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_ACCOUNTANT', 'ROLE_OFFICE_MANAGER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER'].some( role => roles.includes( role )) ? true : false
        };

        const itemStatusCode = item?.status?.code ?? item?._original?.status?.code;
        const itemResponsibleId = item?.responsible?.id ?? item?._original?.responsible?.id;

        if( 
            item 
            && ![ 'cancelled', 'awaiting_confirmation', 'confirmed' ].includes( itemStatusCode ) 
            && ['ROLE_BUYER'].some( role => roles.includes( role )) 
            && itemResponsibleId === UserStore.user.id
        ) {
            rules.update = true;
            rules.delete = true;
        }

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};