import React from 'react';

import { DefaultLayout } from '@/Ship/Layouts/DefaultLayout';
import { AuthLayout } from '@/Ship/Layouts/AuthLayout';

const LoginPage = React.lazy(() => import("@/Containers/PublicSection/AuthContainer/Pages/LoginPage"));
const ForgotPasswordPage = React.lazy(() => import("@/Containers/PublicSection/AuthContainer/Pages/ForgotPasswordPage"));
const Error404Page = React.lazy(() => import("@/Containers/PublicSection/ErrorContainer/Pages/Error404Page"));
const DashboardPage = React.lazy(() => import("@/Containers/IndexSection/IndexContainer/Pages/DashboardPage"));

const ProfilePage = React.lazy(() => import("@/Containers/CompanySection/ProfileContainer/Pages/ProfilePage"));
const UserPage = React.lazy(() => import("@/Containers/CompanySection/UserContainer/Pages/UserPage"));
const DepartmentPage = React.lazy(() => import("@/Containers/CompanySection/DepartmentContainer/Pages/DepartmentPage"));
const SettingPage = React.lazy(() => import("@/Containers/CompanySection/SettingContainer/Pages/SettingPage"));

const ProductPage = React.lazy(() => import("@/Containers/CompanySection/ProductContainer/Pages/ProductPage"));
const WarehousePage = React.lazy(() => import("@/Containers/CompanySection/WarehouseContainer/Pages/WarehousePage"));
const WarehouseServicePage = React.lazy(() => import("@/Containers/CompanySection/WarehouseContainer/Pages/WarehouseServicePage"));

const SupplierPage = React.lazy(() => import("@/Containers/PurchaseSection/SupplierContainer/Pages/SupplierPage"));
const CarrierPage = React.lazy(() => import("@/Containers/PurchaseSection/CarrierContainer/Pages/CarrierPage"));
const PurchasePage = React.lazy(() => import("@/Containers/PurchaseSection/PurchaseContainer/Pages/PurchasePage"));
const PurchaseApplicationPage = React.lazy(() => import("@/Containers/PurchaseSection/ApplicationContainer/Pages/ApplicationPage"));
const PurchaseApplicationItemPage = React.lazy(() => import("@/Containers/PurchaseSection/ApplicationContainer/Pages/ApplicationItemPage"));

const ClientPage = React.lazy(() => import("@/Containers/SaleSection/ClientContainer/Pages/ClientPage"));
const SalePage = React.lazy(() => import("@/Containers/SaleSection/SaleContainer/Pages/SalePage"));
const SaleApplicationPage = React.lazy(() => import("@/Containers/SaleSection/ApplicationContainer/Pages/ApplicationPage"));
const SaleStatusPage = React.lazy(() => import("@/Containers/SaleSection/SaleContainer/Pages/SaleStatusPage"));

const ListIndexPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListIndexPage'));
const ListAccrualTypePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListAccrualTypePage'));
const ListCurrencyRatePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListCurrencyRatePage'));
const ListCurrencyPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListCurrencyPage'));
const ListFormTaxationPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListFormTaxationPage'));
const ListLegalPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListLegalPage'));
const ListPurchaseStatusPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListPurchaseStatusPage'));
const ListOrganizationPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListOrganizationPage'));
const ListTermDeliveryPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListTermDeliveryPage'));
const ListTermPaymentPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListTermPaymentPage'));
const ListPurchaseReasonLosingPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListPurchaseReasonLosingPage'));
const ListSaleStatusPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListSaleStatusPage'));
const ListSaleReasonLosingPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListSaleReasonLosingPage'));
const ListExpenseCategoryPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListExpenseCategoryPage'));
const ListDocumentTypePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListDocumentTypePage'));
const ListPayerPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListPayerPage'));
const ListPaymentTypePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListPaymentTypePage'));
const ListTransportTypePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListTransportTypePage'));
const ListOptionPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListOptionPage'));
const ListCountryPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListCountryPage'));
const ListDocumentSigningTypePage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListDocumentSigningTypePage'));
const ListLoadingLocationPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListLoadingLocationPage'));
const ListRailwayStationPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListRailwayStationPage'));
const ListProductCharacteristicPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/ListProductCharacteristicPage'));
const TokenPage = React.lazy(() => import('@/Containers/SettingSection/ListContainer/Pages/TokenPage'));

export const Routes = [
    { 
        path: '/auth',
        element: <AuthLayout />,
        children: [
            { path: 'login', element: <LoginPage />},
            { path: 'forgot-password', element: <ForgotPasswordPage />}
        ]
    },
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            { index: true, element: <DashboardPage />},
            { path: 'purchase', children: [
                { index: true, element: <></> },
                { path: 'supplier/*', element: <SupplierPage /> },
                { path: 'carrier/*', element: <CarrierPage /> },
                { path: 'purchase/*', element: <PurchasePage /> },
                { path: 'application/*', element: <PurchaseApplicationPage /> },
                { path: 'application-item/*', element: <PurchaseApplicationItemPage /> }
            ]},
            { path: 'sale', children: [
                { index: true, element: <></> },
                { path: 'client/*', element: <ClientPage /> },
                { path: 'sale/*', element: <SalePage /> },
                { path: 'application/*', element: <SaleApplicationPage /> },
                { path: 'sale-status', element: <SaleStatusPage /> }
            ]},
            { path: 'profile', element: <ProfilePage />},
            { path: 'company', children: [
                { path: 'department', element: <DepartmentPage /> },
                { path: 'user', element: <UserPage /> },
                { path: 'setting', element: <SettingPage /> },
                { path: 'product', element: <ProductPage /> },
                { path: 'warehouse', element: <WarehousePage /> },
                { path: 'warehouse-service', element: <WarehouseServicePage /> }
            ]},
            { path: 'list', children: [
                { index: true, element: <ListIndexPage />},
                { path: 'accrual-type', element: <ListAccrualTypePage /> },
                { path: 'currency-rate', element: <ListCurrencyRatePage /> },
                { path: 'currency', element: <ListCurrencyPage /> },
                { path: 'form-taxation', element: <ListFormTaxationPage /> },
                { path: 'legal', element: <ListLegalPage /> },
                { path: 'purchase-status', element: <ListPurchaseStatusPage /> },
                { path: 'organization', element: <ListOrganizationPage /> },
                { path: 'term-delivery', element: <ListTermDeliveryPage /> },
                { path: 'term-payment', element: <ListTermPaymentPage /> },
                { path: 'purchase-reason-losing', element: <ListPurchaseReasonLosingPage /> },
                { path: 'sale-status', element: <ListSaleStatusPage /> },
                { path: 'sale-reason-losing', element: <ListSaleReasonLosingPage />},
                { path: 'expense-category', element: <ListExpenseCategoryPage /> },
                { path: 'document-type', element: <ListDocumentTypePage /> },
                { path: 'payer', element: <ListPayerPage /> },
                { path: 'payment-type', element: <ListPaymentTypePage /> },
                { path: 'transport-type', element: <ListTransportTypePage /> },
                { path: 'option', element: <ListOptionPage /> },
                { path: 'country', element: <ListCountryPage /> },
                { path: 'document-signing-type', element: <ListDocumentSigningTypePage /> },
                { path: 'loading-location', element: <ListLoadingLocationPage /> },
                { path: 'railway-station', element: <ListRailwayStationPage /> },
                { path: 'product-characteristic', element: <ListProductCharacteristicPage /> },
                { path: 'token', element: <TokenPage /> }
            ]}
        ]
    },
    {
        path: '*',
        element: <Error404Page />
    }
];