import { Space, Typography } from "antd";
import { HeaderPageContextProvider, createHeaderPageContext } from './HeaderPageContextProvider';
import { Dependency } from "@/Core/UI/Dependency";
import { useCrudResourceContext } from "../CrudResource/CrudResourceContextProvider";
import { ButtonGroup } from "@/Core/UI/ButtonGroup";

import './HeaderPage.css';
import { useDefaultLayoutContext } from "../../Layouts/DefaultLayout/DefaultLayoutContextProvider";

const { Title } = Typography;

export const HeaderPage = ({ 
    title, 
    titleLevel = 3,
    titleExtra, 
    extra, 
    headerPageContext, 
    eventHandlers,
    storages, 
    settings,
    headerPageWrapperStyle = { margin: '0 15px 15px 15px' }, 
    customEventHandlers = {},
    template = null
}) => {
    
    title = title ?? false;
    titleExtra = titleExtra ?? [];
    extra = extra ?? [];

    if( !headerPageContext ) 
        headerPageContext = createHeaderPageContext({ 
            title, 
            titleExtra: [...titleExtra], 
            extra: Array.isArray(extra) ? [...extra] : extra,
            eventHandlers,
            storages, 
            settings,
            template
        });
    
    const crudContext = useCrudResourceContext();
    let content = [];
    
    headerPageContext.eventManager.run('beforeInit', { headerPageContext, crudContext });

    if( typeof customEventHandlers?.run === 'function' )
        customEventHandlers.run('beforeInit', { headerPageContext, crudContext });

    if( headerPageContext?.settings?.isDisableButtons !== true ) {

        let buttons = headerPageContext?.storages?.buttons ?? {};

        headerPageContext.eventManager.run('onButtons', { buttons, headerPageContext, crudContext, content });
        
        if( typeof customEventHandlers?.run === 'function' )
            customEventHandlers.run('onButtons', { buttons, headerPageContext, crudContext, content });

        headerPageContext.extra.push(<ButtonGroup buttons={buttons} key='header-page-group-buttons' />);
    }
    
    const defaultLayoutContext = useDefaultLayoutContext();

    let Template = ({ headerPageContext, defaultLayoutContext }) => <div style={headerPageWrapperStyle}>
        <div className='wl-header-page--wrapper'>
            <div className='wl-header-page--wrapper-left'>
                <Dependency depFn={ ({ headerPageContext, defaultLayoutContext }) => !!headerPageContext.title && !defaultLayoutContext.isMobileMode } depStore={{ headerPageContext, defaultLayoutContext }}>
                    <div className='wl-header-page--title'>
                        <Title level={titleLevel} style={{ margin: 0 }} ellipsis={true}>{headerPageContext.title}</Title>
                    </div>
                </Dependency>
                {
                    headerPageContext?.titleExtra 
                    && <div className='wl-header-page--title-extra'><Space>{headerPageContext.titleExtra}</Space></div>
                }
            </div>
            <div className='wl-header-page--wrapper-right'>
                {headerPageContext.extra.length > 0 && <div className='wl-header-page--extra'>{headerPageContext.extra}</div>}
                {headerPageContext?.afterExtra && <div className='wl-header-page--after-extra'>{headerPageContext.afterExtra}</div>}
            </div>
        </div>
    </div>
    
    if( headerPageContext.template )
        Template = headerPageContext.template;

    return <HeaderPageContextProvider value={ headerPageContext }>
        <Template headerPageContext={headerPageContext} defaultLayoutContext={defaultLayoutContext} />
        {content}
    </HeaderPageContextProvider>;
}