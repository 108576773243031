import { Form, FormField, FormRow } from "@/Core/UI/Form";
import { Tabs } from "@/Core/UI/Tabs";
import { PriceCurrencyField } from "@/Ship/Components/PriceCurrencyField";
import { ApplicationItemCrud } from '../Components/ApplicationItemCrud';
import { Dependency } from "@/Core/UI/Dependency";
import { ChangesList } from '../Components/ChangesList';
import { ApplicationExpenseCrud } from '../Components/ApplicationExpenseCrud';
import { Collapse } from 'antd';

export const ApplicationFormTemplate = ({ formContext }) => {

    const collapseLocalStoreKey = `collapse-application-${formContext.values?.id}`
    const collapseDefaultActiveKey = [localStorage.getItem(collapseLocalStoreKey) === 'true' ? 'transport' : null];

    const tabItems = [
        {
            label: 'Загальне',
            key: 'general',
            children: <>
                <Dependency depFn={({ crudContext }) => !!crudContext.formContext.values?.id && crudContext.formContext.values?.termDelivery?.code !== 'exw' }>
                    <FormRow>
                        <FormRow.Col>
                            <div style={{ marginBottom: 15 }}>
                                <Collapse 
                                    items={[{
                                        key: 'transport',
                                        label: 'Транспорт',
                                        children: <ApplicationItemCrud />
                                    }]} 
                                    defaultActiveKey={collapseDefaultActiveKey}
                                    onChange={(tab) => {
                                        if( tab[1] === undefined )
                                            localStorage.setItem(collapseLocalStoreKey, false);
                                        else
                                            localStorage.setItem(collapseLocalStoreKey, true);
                                    }}
                                />
                            </div>
                        </FormRow.Col>
                    </FormRow>
                </Dependency>
                <FormRow>
                    <FormRow.Col>
                        <Form formContext={formContext}>
                            <FormField name='supplier' />
                            <FormField name='purchase' />
                            <FormField name='date' />
                            <FormField name='responsible' />
                            <FormField name='product' />
                            <FormField name='loadingLocation' />
                            <FormField name='unloadingLocation' />
                            <FormField name='railwayStation' />
                            <Dependency depFn={({ crudContext }) => crudContext.formContext.values?.termDelivery?.code !== 'exw' }>
                                <FormField name='transportPayer' />
                                <FormField name='typePaymentCarrier' depFn={({ field, formContext }) => {
                                    if( formContext.values?.transportPayer?.code === "our_company" ) {
                                        field.setIsRequired( true );
                                    }
                                    else {
                                        field.setIsRequired( false );
                                    }

                                }} />
                                <FormField name='organization' depFn={({ field, formContext }) => {
                                    if( formContext.values?.typePaymentCarrier?.code === "cash" ) {
                                        field.setIsRequired( true );
                                    }
                                    else {
                                        field.setIsRequired( false );
                                    }

                                }} />
                                <FormField name='typeTransport' />
                                <FormField name='transportPriceUAH' depFn={({ formContext, field }) => {
                                    if( formContext.values?.termDelivery?.code !== 'exw' ) {
                                        field.setIsRequired( true );
                                    }
                                    else {
                                        field.setIsRequired( false );
                                    }
                                }} />
                                <FormField name='carrier' />
                            </Dependency>
                        </Form>
                    </FormRow.Col>
                    <FormRow.Col>
                        <Form formContext={formContext}>
                            <PriceCurrencyField 
                                priceFieldName='price' 
                                currencyFieldName='priceCurrency' 
                            />
                            <FormField name='termDelivery' />
                            <FormField name='isUnloadedConfirmed' />
                            <FormField name='totalUnloadedQuantity' depFn={({ formContext, field }) => {
                                
                                if( formContext.values?.termDelivery?.code === 'exw' )
                                    field.mergeInputProps({ disabled: false })
                                else
                                    field.mergeInputProps({ disabled: true })

                            }} />
                            <FormField name='totalTargetWeight' depFn={({ formContext, field }) => {
                                
                                if( formContext.values?.termDelivery?.code === 'exw' )
                                    field.mergeInputProps({ disabled: false })
                                else
                                    field.mergeInputProps({ disabled: true })

                            }} />
                            <FormField name='dateUnloading' />
                            <Dependency depFn={({ crudContext }) => crudContext.formContext.values?.termDelivery?.code !== 'exw' }>
                                <FormField name='totalLoadedQuantity' />
                            </Dependency>
                        </Form>
                    </FormRow.Col>
                </FormRow>
            </>
        },
        {
            label: 'Історія змін',
            key: 'events',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <ChangesList />
        },
        {
            label: 'Витрати',
            key: 'expenses',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <ApplicationExpenseCrud />
        }
    ];

    return <Tabs items={tabItems} />
}