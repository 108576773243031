
import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { toJS } from "mobx";
import { SaleInvoiceFormTemplate } from "../Templates/SaleInvoiceFormTemplate";

export const SaleInvoiceCrud = () => {
    
    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Sale.SaleInvoice'
        isFilter={false}
        form={{
            initialValues: {
                sale: toJS(crudContext.formContext.values)
            },
            template: SaleInvoiceFormTemplate
        }}
        table={{
            showColumns: [
                'documentNumber', 'date', 'type', 'status', 
                'amountUAH', 'paidAmountUAH', 'tonnage', 'basCode'
            ]
        }}
        drawer={{
            width: 800
        }}
    />
}