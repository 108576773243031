import { Form, FormField } from "@/Core/UI/Form";
import { SaleInvoiceChangesList } from '../Components/SaleInvoiceChangesList';
import { Tabs } from "@/Core/UI/Tabs";

export const SaleInvoiceFormTemplate = ({ formContext }) => {

    const tabItems = [
        {
            label: 'Загальне',
            key: 'general',
            children: <Form formContext={formContext}>
                <FormField name='documentNumber' />
                <FormField name='date' />
                <FormField name='type' />
                <FormField name='status' />
                <FormField name='amountUAH' />
                <FormField name='paidAmountUAH' />
                <FormField name='tonnage' />
                <FormField name='basCode' />
            </Form>
        },
        {
            label: 'Історія змін',
            key: 'events',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <SaleInvoiceChangesList />
        }
    ];

    return <Tabs items={tabItems} />;
}