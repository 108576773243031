import { List as ListCore } from "@/Core/UI/List";
import { useCrudResourceContext } from '../CrudResourceContextProvider';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { observer } from 'mobx-react-lite';
import { createListContext } from '@/Core/UI/List/ListContextProvider';
import { GetRestUriTask } from "@/Core/Tasks/Resources/GetRestUriTask";

export const List = (({ list }) => {
    
    const listConfig = ConfigStore.get('ListConfig', {});
    const crudContext = useCrudResourceContext();
    const listContext = createListContext({
        ...( typeof listConfig === 'object' ? listConfig : {} ),
        prefilter: crudContext.prefilter ?? {},
        ...list // TODO: !!!
    });

    let modeItem = null;
    crudContext.setListContext( listContext );

    if( typeof crudContext.filterFormContext?.initialValues === 'object' )
        crudContext.listContext.setFilter(crudContext.filterFormContext.initialValues);

    // TODO: !!!
    if( crudContext.mode == undefined )
        crudContext.setMode('table');

    const ListObserver = observer(() => {
       
        if( 
            crudContext.mode 
            && crudContext?.modeItems
        ) {
            // TODO: !!!
            modeItem = crudContext.modeItems[crudContext.mode] ?? crudContext.modeItems['table'];
        }
    
        if( !modeItem || !modeItem?.component )
            return null;
    
        const ListComponent = modeItem?.component;
        const listComponentProps = modeItem?.props ?? {};
        const queryUri = GetRestUriTask(crudContext?.resource?.restUri, 'getCollection', (list?.restUriReplaceParams ?? {}));
        const queryKey = [crudContext?.resource?.key];

        list ??= {};

        return <ListCore
            {...list}
            queryUri={ modeItem?.queryUri ?? queryUri }
            queryKey={ modeItem?.queryKey ?? queryKey }
            listContext={listContext}
            // TODO: !!!
            fields={list.fields === undefined && crudContext?.resource?.fields ? crudContext.resource.fields : {}}
        >
            <ListComponent {...listComponentProps} />
        </ListCore>;
    });

    return <ListObserver />;

});