import { observer } from 'mobx-react-lite';
import { App, Button, List, Modal, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from "@/Core/UI/Tabs";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { Form, FormField } from '@/Core/UI/Form';
import { createFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';

import { LoadDetailCardStatusListTask } from '../Tasks/LoadDetailCardStatusListTask';
import { StatusBarDetailStore } from '../Stores/StatusBarDetailStore';

import { IoMdCheckmark } from "react-icons/io";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { LuBadgeMinus } from "react-icons/lu";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";

import './StatusBarDetail.css';

const { Text } = Typography;

export const StatusBarDetail = () => {

    const restClient = ServiceStore.get('restClient');
    const crudContext = useCrudResourceContext();
    const { notification } = App.useApp();
    const { t } = useTranslation();

    const loseFormContext = createFormContext({
        fields: {
            reasonLosing: {
                label: null,
                type: 'select',
                isRequired: true,
                resource: 'Setting.ListPurchaseReasonLosing'
            }
        },
        onSubmit: ({ values }) => {

            restClient.patch(`/core_api/purchase/purchases/lose_status`, {
                json: {
                    entity: crudContext.formContext.values['@id'],
                    reasonLosing: values.reasonLosing['@id'] 
                }
            })
            .getResponse()
            .then( () => {

                notification.success({
                    duration: 5,
                    message: 'Успіх',
                    description: 'Закупівлю успішно програно.',
                    placement: 'bottomRight'
                });

                StatusBarDetailStore.setIsModalOpen( false );
                StatusBarDetailStore.setCurrentStatus( StatusBarDetailStore.loseStatus );
                crudContext.listContext.actions.reload();
        })
        }
    });

    useEffect(() => {
        LoadDetailCardStatusListTask();
    }, []);

    if( !crudContext || !crudContext?.formContext.values?.status )
        return;

    StatusBarDetailStore.setCurrentStatus(crudContext.formContext.values.status);
    StatusBarDetailStore.setApplicationId(crudContext.formContext.values.id);
    
    const showHelpModal = ( item ) => {
        crudContext.formContext.setErrors({});
        restClient.post(`/core_api/crm/applications/${crudContext.formContext.values.id}/status`, {
            json: { status: item['@id'] }
        })
        .getResponse()
        .catch( async error => {
            const response = await error.response.json();
            crudContext.formContext.setIsEditable( true );

            if( response && Array.isArray(response.violations) ) {
                let errors = {};
                let otherErrors = [];

                response.violations.map( field => {

                    if( field.propertyPath.length > 0 )
                        errors[ field.propertyPath ] = field.message;
                    // else
                        otherErrors.push(`- ${field.message}`);
                });
                
                if( otherErrors.length > 0 )
                    notification.info({
                        duration: 10,
                        message: 'Для зміни статусу необхідно',
                        className: 'wl-status-bar-detail-notification',
                        description: <List
                            size="small"
                            bordered={true}
                            dataSource={otherErrors}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />,
                        placement: 'bottomRight'
                    });
                
                if( Object.keys(errors).length > 0) {
                    crudContext.formContext.setErrors(errors);
                }
                
            }
                
        });
    }

    const StatusBarDetailObserver = observer(() => {

        if( StatusBarDetailStore.statusList.length === 0 || !StatusBarDetailStore.currentStatus )
            return <Skeleton paragraph={false} active={true} />;

        let isFillBackground = true;
        
        return <>
            <Tabs
                className='wl-status-bar-detail'
                activeKey={StatusBarDetailStore.currentStatus['@id']}
                items={StatusBarDetailStore.statusList.map( item => {
                    
                    if( item.code === "cancelled" )
                        StatusBarDetailStore.setLoseStatus(item);

                    const itemTransformed = {
                        label: <div
                            className='title' 
                            style={{ 
                                backgroundColor: isFillBackground 
                                    ? StatusBarDetailStore.currentStatus.color 
                                    : 'var(--ant-control-item-bg-hover)' 
                            }}
                        >
                            {/* {!isFillBackground && <a href="#" onClick={(e) => {
                                e.preventDefault();
                                showHelpModal(item);
                            }} style={{
                                paddingRight: 4,
                                height: 22,
                                position: 'relative',
                            }}>
                                <IoMdInformationCircleOutline style={{ fontSize: 22 }}/>
                            </a>} */}
                            {!isFillBackground && <span style={{
                                paddingRight: 4,
                                height: 22,
                                position: 'relative'
                            }}>
                                <MdOutlineRadioButtonUnchecked style={{ fontSize: 22 }}/>
                            </span>}
                            {isFillBackground && <span style={{
                                paddingRight: 4,
                                height: 22,
                                position: 'relative'
                            }}>
                                <IoMdCheckmark style={{ fontSize: 22 }}/>
                            </span>}
                            <Text
                                style={{ width: 110 }}
                                ellipsis={{ tooltip: item.title }}
                            >
                                {item.title}
                            </Text>
                        </div>,
                        key: item['@id'],
                        color: item.color,
                        id: item.id
                    };

                    if( item['@id'] === StatusBarDetailStore.currentStatus['@id'] )
                        isFillBackground = false;

                    return itemTransformed;
                })}
                tabBarExtraContent={
                    StatusBarDetailStore.currentStatus.code !== "cancelled" ? <Button 
                        type='primary' 
                        danger={true} 
                        icon={<LuBadgeMinus  style={{ fontSize: 24 }} />}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                            e.preventDefault();
                            StatusBarDetailStore.setIsModalOpen(true);
                        }}
                    >
                        Анулювати
                    </Button> : null
                }
                renderTabBar={(props, Component) => {
                    props.popupClassName += ' wl-status-bar-detail-dropdown';
                    return <Component {...props} />
                }}
            />
            <Modal 
                title={'Оберіть причину анулювання'}
                open={StatusBarDetailStore.isModalOpen} 
                onCancel={() => StatusBarDetailStore.setIsModalOpen(false) }
                onOk={() => loseFormContext.submit()}
                okText={'Зберегти'}
                cancelButtonProps={{
                    style: { display: 'none' }
                }}
                destroyOnClose={true}
            >
                <Form formContext={loseFormContext}>
                    <FormField name='reasonLosing' />
                </Form>
            </Modal>
        </>;
    });

    return <StatusBarDetailObserver />;
}