import { App } from "antd";
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";
import { ResourceCorrespondenceConfig } from "../Configs/ResourceCorrespondenceConfig";
import { ReplaceBBCodeTask } from '@/Ship/Tasks/ReplaceBBCodeTask';

export const useShowInfoNotificationTask = () => {

    const { notification } = App.useApp();

    const resources = {
        application: "Purchase.Application",
        sale: "Sale.Sale",
        purchase: "Purchase.Purchase"
    };

    const defaultLayoutContext = useDefaultLayoutContext();

    const createClickHandler = (type, id) => (e) => {
        e.preventDefault();
        defaultLayoutContext.openResource({ 
            resourceCode: ResourceCorrespondenceConfig[type], 
            resourceId: id,
            crudResourceProps: {
                drawer: {
                    zIndex: 20000
                }
            }
        });
    };

    const handleClick = (e) => {
        if (e.target.tagName === 'A' && e.target.hasAttribute('data-type') && e.target.hasAttribute('data-id')) {
            const type = e.target.getAttribute('data-type');
            const id = e.target.getAttribute('data-id');
            createClickHandler(type, id)(e);
        }
    };

    const ShowInfoNotificationTask = (message, description) => {
    
        notification.info({
            duration: 3,
            message,
            description: <div 
                dangerouslySetInnerHTML={{ __html: ReplaceBBCodeTask(description) }} 
                onClick={handleClick}
            />,
            placement: 'bottomRight'
        });
    
        return false;
    }

    return ShowInfoNotificationTask;
}