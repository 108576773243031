export const ResourceCorrespondenceConfig = {
    applications: "Purchase.Application",
    sales: "Sale.Sale",
    purchases: "Purchase.Purchase",
    departments: 'Company.Department',
    users: 'Company.User',
    organizations: 'Setting.ListOrganization',
    warehouses: 'Company.Warehouse',
    carriers: 'Purchase.Carrier',
    suppliers: 'Purchase.Supplier',
    clients: 'Sale.Client',
    legal_entities: 'Setting.ListLegal',
    products: 'Company.Product'
};