import { TbCreditCardPay } from "react-icons/tb";
import { useFormModal } from '@/Ship/Hooks/useFormModal';
import { UserStore } from "@/Core/Stores/UserStore";

export const useBonusesPaidButtonHook = ({ crudContext }) => {

    const { Modal, localStorage } = useFormModal({
        fields: {
            paid: {
                type: 'number',
                isRequired: true
            }
        },
        modalProps: {
            title: 'Вкажіть значення бонусу'
        },
        method: 'patch',
        restUri: '/core_api/purchase/purchases/bonuses_paid',
        onAfterSuccessRequest: () => {
            crudContext.actions.reload();
            crudContext.listContext.actions.reload();
        },
        onBeforeSubmit: ({ values }) => {
            values.entity = crudContext.formContext.values['@id'];
        }
    });

    const roles = UserStore?.user?.roles ?? [];

    const bonusesPaidButton = {
        label: 'Виплатити бонус',
        icon: <TbCreditCardPay />,
        type: 'primary',
        depFn: ({ crudContext }) => !!crudContext.formContext.values?.id && ['implementation', 'done', 'awaiting_confirmation', 'confirmed'].includes(crudContext.formContext.values?.status?.code) && [ 'ROLE_ADMIN', 'ROLE_SUPER_USER' ].some( role => roles.includes(role)),
        onClick: () => localStorage.setIsModalOpen(true)
    };

    return {
        bonusesPaidButton,
        BonusesPaidModal: Modal
    }
}