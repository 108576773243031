import { action, makeObservable, observable } from "mobx";
import { UIBaseStore } from "@/Core/Stores/Base/UIBaseStore";

export class HeaderPageStore extends UIBaseStore {

    title = false;
    titleExtra = [];
    extra = [];
    afterExtra = [];

    constructor({
        title, 
        titleExtra, 
        afterExtra,
        extra,
        ...props
    }) {
        
        super(props);
        
        makeObservable( this, {
            title: observable, 
            setTitle: action
        });

        if( title !== undefined )
            this.setTitle( title );

        if( Array.isArray(titleExtra) )
            this.titleExtra = titleExtra;

        if( Array.isArray(extra) )
            this.extra = extra;

        if( Array.isArray(afterExtra) )
            this.afterExtra = afterExtra;
    }

    setTitle( title ) {
        this.title = title;
    }
}