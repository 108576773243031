import { Button, Drawer, Table } from 'antd';
import { observer } from "mobx-react-lite";

import { Form, FormField } from "@/Core/UI/Form";
import { createFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { CiSearch } from "react-icons/ci";
import { makeAutoObservable, toJS } from 'mobx';
import { AutoSearchPurchaseBySaleTask } from '../Tasks/AutoSearchPurchaseBySaleTask';
import { useShowInfoNotificationTask } from '@/Ship/Tasks/ShowInfoNotificationTask';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import dayjs from "dayjs";
import { AppConfig } from '@/Ship/Configs/AppConfig';
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";

import i18n from '@/Ship/i18n';

import './useAutoSearchSaleItemButtonHook.css';

export const useAutoSearchSaleItemButtonHook = ({ loadItems }) => {

    const restClient = ServiceStore.get('restClient');
    const crudContext = useCrudResourceContext();
    const showInfoNotificationTask = useShowInfoNotificationTask();
    const showErrorNotificationTask = useShowErrorNotificationTask();
    const defaultLayoutContext = useDefaultLayoutContext();

    const localStorage = makeAutoObservable({
        isSendRequest: false,
        isDrawerOpen: false,
        items: [],
        setIsSendRequest( isSendRequest ) {
            if( typeof isSendRequest === 'boolean' )
                this.isSendRequest = isSendRequest;
        },
        setIsDrawerOpen( isDrawerOpen ) {
            if( typeof isDrawerOpen === 'boolean' )
                this.isDrawerOpen = isDrawerOpen;
        },
        setItems( items ) {
            if( Array.isArray(items) )
                this.items = items;
        }
    });

    const formContext = createFormContext({
        onSubmit: async ({ values }) => {

            let items = [];

            Object.keys(values).map( index => {

                if( values[index]?.select !== true )
                    return;

                items.push({
                    ...localStorage.items[index],
                    ...values[index]
                });
            });
            
            if( items.length === 0 ) {
                showErrorNotificationTask('Помилка', 'Оберіть одну або декілька закупівель.');
                return;
            }

            localStorage.setIsSendRequest( true );
            
            for( let i = 0; i < items.length; i++ ) {
                const json = {
                    entity: crudContext.formContext.values['@id'],
                    purchase: items[i]['@id'],
                    quantity: items[i].quantity
                };

                await restClient.post('/core_api/sale/items', { json }).getResponse();
            }

            localStorage.setIsSendRequest( false );
            localStorage.setIsDrawerOpen( false );
            loadItems();
            crudContext.formContext.setInitialValue('items', items);
        }
    });

    const SaveButton = observer(() => 
        <Button 
            type='primary' 
            loading={localStorage.isSendRequest} 
            onClick={() => formContext.submit()} 
            size='middle'
        >
            Зберегти
        </Button>
    );

    const columns = [
        {
            title: '',
            dataIndex: 'select',
            key: 'select',
            render: (value, item, index) => {              
                return <FormField 
                    name={[index, 'select']} 
                    type='switch'
                />
            }
        }, 
        {
            title: 'Дата',
            dataIndex: 'dateContract',
            key: 'dateContract',
            render: ( value ) => value ? (new dayjs(value)).format(AppConfig.dateDefaultFormat) : '-'
        },
        {
            title: 'Номер договору',
            dataIndex: 'contractNumber',
            key: 'contractNumber'
        },
        {
            title: 'Закупівля',
            dataIndex: 'purchase',
            key: 'purchase',
            render: ( value, item ) => <a href="#" onClick={() => { 
                defaultLayoutContext.openResource({ 
                    resourceCode: 'Purchase.Purchase', 
                    resourceId: item.id
                })
            }}>Відкрити</a>
        },
        {
            title: 'Постачальник',
            dataIndex: 'supplier',
            key: 'supplier',
            render: ( value, item ) => {

                if( !item.supplier )
                    return '-';

                return <a href="#" onClick={() => { 
                    defaultLayoutContext.openResource({ 
                        resourceCode: 'Purchase.Supplier', 
                        resourceId: item.supplier.id
                    })
                }}>{item.supplier.title}</a>
            }
        },
        {
            title: 'Номенклатура',
            dataIndex: ['product', 'title'],
            key: 'product',
        },
        {
            title: 'Характеристики',
            dataIndex: 'characteristics',
            key: 'characteristics',
            render: ( value, item ) => {
                return item.characteristics.map( char => char.title ).join(', ')
            }
        },

        {
            title: 'Ціна',
            dataIndex: 'price',
            key: 'price',
            render: ( value, item ) => {
                let price = item.price;

                if( item.priceCurrency.formatting )
                    price = item.priceCurrency.formatting.replace('#', price);

                return price;
            }
        },
        {
            title: 'Вільний залишок',
            dataIndex: 'salesRemainder',
            key: 'salesRemainder'
        },
        {
            title: 'Кількість',
            dataIndex: 'quantity',
            key: 'quantity',
            render: ( value, item, index ) => {
                return <FormField 
                    name={[index, 'quantity']} 
                    type='number'
                    initialValue={item.salesRemainder}
                    inputProps={{ max: item.salesRemainder }}
                />
            }
        },
        {
            title: 'Опціон',
            dataIndex: 'optionPercentage',
            key: 'optionPercentage'
        },
        {
            title: 'Умови поставки',
            dataIndex: 'termDelivery',
            key: 'termDelivery',
            render: ( value, item ) => {
                return item?.termDelivery?.title ?? '-';
            }
        }
    ];

    const AutoSearchSaleItemDrawer = observer(() => {

        return <>
            <Drawer 
                title={'Оберіть закупівлі'}
                width={1500}
                open={localStorage.isDrawerOpen} 
                destroyOnClose={true} 
                onClose={() => localStorage.setIsDrawerOpen(false) } 
                extra={<SaveButton />}
                className='select-purchase-drawer'
            >
                <Form formContext={formContext}>
                    <Table 
                        columns={columns} 
                        dataSource={toJS(localStorage.items)} 
                        size='small'
                        expandable={{
                            expandedRowRender: (record) => <Table 
                                pagination={false}
                                columns={[
                                    {
                                        title: 'Місце завантаження',
                                        dataIndex: 'loadingLocation',
                                        key: 'loadingLocation',
                                        render: ( value, item ) => {
                                            return item?.loadingLocation?.title ?? '-';
                                        }
                                    },
                                    {
                                        title: 'Місце вивантаження',
                                        dataIndex: 'unloadingLocation',
                                        key: 'unloadingLocation',
                                        render: ( value, item ) => {
                                            return item?.unloadingLocation?.title ?? '-';
                                        }
                                    },
                                    {
                                        title: 'Залізнична станція',
                                        dataIndex: 'railwayStation',
                                        key: 'railwayStation',
                                        render: ( value, item ) => {
                                            return item?.railwayStation?.title ?? '-';
                                        }
                                    }
                                ]} 
                                dataSource={record?.applications ?? []}
                            />,
                            // rowExpandable: (record) => record.name !== 'Not Expandable',
                        }}
                    />   
                </Form>
            </Drawer>
        </>
    });

    return { 
        AutoSearchSaleItemButton: {
            label: 'Знайти закупівлі', 
            icon: <CiSearch style={{ fontSize: 24 }} />, 
            sort: 100, 
            priority: 'button',
            size: 'middle',
            type: 'primary',
            depFn: ({ crudContext }) => crudContext.accessManager.isCan('create') === true,
            style: { display: 'flex', alignItems: 'center', position: 'relative' },
            onClick: async () => {

                const items = await AutoSearchPurchaseBySaleTask(crudContext.formContext.values.id);
                
                if( !Array.isArray(items) || items.length === 0 ) {
                    showInfoNotificationTask('Автоматичний пошук закупівель', 'Нічого не знайдено.');
                    return;
                }
                    
                localStorage.setItems( items );
                localStorage.setIsDrawerOpen( true );
            }
        }, 
        AutoSearchSaleItemDrawer: <AutoSearchSaleItemDrawer />
    }
}