import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { Descriptions, List, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import dayjs from 'dayjs';
import { AppConfig } from '@/Ship/Configs/AppConfig';

export const SaleInvoiceChangesList = () => {

    const crudContext = useCrudResourceContext();
    const restClient = ServiceStore.get('restClient');

    const [ items, setItems ] = useState([]);

    const id = crudContext.formContext.values.id;

    const loadItems = (id) => {
        if( id )
            restClient
                .get(`/core_api/system/invoices/changes?objectId=${id}`)
                .getResponse()
                .then( response => {
                    if( response && Array.isArray( response['hydra:member'] )) 
                        setItems(response['hydra:member']);
                });
    }

    useEffect(() => {
        loadItems(id);
    }, []);

    const getTitle = item => {
        let title = [ item.action === 'create' ? <Tag color='#96eaa1'>Створено</Tag> : <Tag color='#eae296'>Оновлено</Tag>] ;

        const createdDate = new dayjs(item.createdAt);

        title.push(<span style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.45)' }}>{createdDate.format(AppConfig.datetimeDefaultFormat)}</span>)

        if( item?.createdBy?.fullName ) {
            title.push( <span style={{ padding: '0 5px' }}>|</span> );
            title.push(item.createdBy.fullName);
        }

        return <Space>{title}</Space>;
    }

    const getDescriptionValue = value => {
        
        if( typeof value === 'string' || typeof value === 'number' )
            return value;

        if( typeof value === 'boolean' )
            return value === true ? 'Так' : 'Ні';

        if( typeof value === 'object' && value?.date )
            return (new dayjs(value.date)).format(AppConfig.datetimeDefaultFormat);

        if( typeof value === 'object' && value?.color )
            return <Tag color={value.color}>{value.title}</Tag>

        if( typeof value === 'object' && value?.title )
            return value.title;

        if( typeof value === 'object' && value?.fullName )
            return value.fullName;

        if( typeof value === 'object' && value?.contractNumber )
            return value.contractNumber;

        return '-';
    }

    const getDescriptions = ( item ) => {
        return Object.keys(item.normalizerData).filter( key => !!crudContext.formContext.fieldsSource[key]?.label ).map( ( key ) => ({
            id: key,
            label: crudContext.formContext.fieldsSource[key].label,
            children: getDescriptionValue(item.normalizerData[key])
        }));
    }

    return <List
        size="small"
        bordered
        dataSource={items}
        renderItem={(item) => <List.Item>
            <Descriptions title={getTitle(item)} items={getDescriptions(item)}/>
        </List.Item>}
    />
}