import { DefaultLayout as CoreDefaultLayout } from "@/Core/Layouts/DefaultLayout";

import { AntdConfig } from "@/Ship/Configs/AntdConfig";
import { AppConfig } from "@/Ship/Configs/AppConfig";
import { FormConfig } from '@/Ship/Configs/FormConfig';
import { ListConfig } from "@/Ship/Configs/ListConfig";
import { TableConfig } from "@/Ship/Configs/TableConfig";
import { CrudResourceConfig } from "@/Ship/Configs/CrudResourceConfig";
import { DefaultValueConfig } from "@/Ship/Configs/DefaultValueConfig";

import { Services } from "@/Ship/Services";

import { Menu as menuItems } from "@/Ship/Menu";
import { Resources } from "@/Ship/Resources";
import { Divider } from 'antd';

import { UserInfo } from "@/Core/Components/Layout/FirstBar/UserInfo";
import { Menu } from "@/Core/Components/Layout/FirstBar/Menu";

// import { FavoriteList } from "@/Ship/Components/FavoriteList";

import styles from './DefaultLayout.module.css';
import './CustomContent.css';
import { MercureHandler } from './Components/MercureHandler';
import { Search } from '@/Core/Components/Layout/FirstBar/Search';
import { NotificationBarIcon } from "@/Containers/CommunicationSection/NotificationContainer/Components/NotificationBarIcon";

export const DefaultLayout = () => {
    
    return <>
        <CoreDefaultLayout 
            configs={{
                AntdConfig, AppConfig, FormConfig,
                ListConfig, TableConfig, CrudResourceConfig,
                DefaultValueConfig
            }}
            services={Services}
            resources={Resources}
            storages={{
                menuItems
            }}
            firstBarContent={<>
                <UserInfo key='firstbar-userinformation' />
                <Divider key='firstbar-divider-1' />
                <Search />
                <Divider key='firstbar-divider-2' />
                <div style={{ height: 'calc(100% - 170px)'}}>
                    <Menu key='firstbar-menu' />
                </div>
            </>}
            isShowSecondBar={false}
            secondBarContent={[
                // <FavoriteList key='secondbar-favorite' />
                // <NotificationBarIcon key='secondbar-notification-bar' />
            ]}
            mobileBarContent={[
                // <FavoriteList key='secondbar-favorite-mobile' />
            ]}
            customStyle={styles}
            scripts={<MercureHandler />}
        />
    </>
}