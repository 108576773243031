import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { IoRefresh } from "react-icons/io5";

export const PurchaseExportBasCrud = () => {

    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Purchase.PurchaseExportBas'
        list={{
            restUriReplaceParams: {
                entity_id: crudContext.formContext.values.id
            }
        }}
        isFilter={false}
        headerPage={{
            eventHandlers: {
                onButtons: {
                    refreshButton: ({ buttons, headerPageContext, crudContext }) => {
                        buttons.refresh = {
                            type: 'primary',
                            label: 'Оновити',
                            icon: <IoRefresh style={{ fontSize: 20 }} />,
                            onClick: () => crudContext.listContext.actions.reload()
                        }
                    }
                },
            }
        }}
    />
}