import { toJS } from "mobx";
import { TransformPriceCurrencyViewFieldTask } from '@/Ship/Tasks/TransformPriceCurrencyViewFieldTask';
import { DefaultValueConfig } from '@/Ship/Configs/DefaultValueConfig';
import { useFactShipmentButton } from '../Hooks/useFactShipmentButton';
import { ApplicationFormTemplate } from '../Templates/ApplicationFormTemplate';
import { UserStore } from "@/Core/Stores/UserStore";

export const ApplicationResource = {
    title: 'Заявки на переміщення',
    fields: {
        date: {
            label: 'Дата',
            isRequired: true,
            type: 'date'
        },
        responsible: {
            label: 'Відповідальний',
            type: 'select',
            resource: 'Company.User'
        },
        supplier: {
            label: 'Постачальник',
            isRequired: true,
            type: 'select',
            resource: 'Purchase.Supplier',
            isLink: true
        },
        purchase: {
            label: 'Контракт',
            isRequired: true,
            type: 'select',
            resource: 'Purchase.Purchase',
            responseLabel: value => value.contractNumber,
            view: value => value.contractNumber,
            inputProps: {
                disabled: true
            }
        },
        transportPayer: {
            label: 'Платник по транспорту',
            type: 'select',
            resource: 'Setting.ListPayer'
        },
        typePaymentCarrier: {
            label: 'Тип оплати перевізнику',
            type: 'select',
            resource: 'Setting.ListPaymentType'
        },
        organization: {
            label: 'Юридична особа платник',
            type: 'select',
            resource: 'Setting.ListOrganization'
        },
        product: {
            label: 'Номенклатура',
            type: 'select',
            resource: 'Company.Product',
            isLink: true
        },
        typeTransport: {
            label: 'Тип транспорту',
            type: 'select',
            resource: 'Setting.ListTransportType'
        },
        termDelivery: {
            label: 'Умови доставки',
            type: 'select',
            resource: 'Setting.ListTermDelivery',
            inputProps: {
                disabled: true
            }
        },
        price: {
            label: 'Ціна закупівлі (т)',
            type: 'number',
            inputProps: {
                disabled: true
            },
            view: ( value, formContext ) => TransformPriceCurrencyViewFieldTask({
                value,
                values: toJS( formContext.initialValues ),
                costCurrencyFieldName: 'priceCurrency'
            })
        },
        priceCurrency: {
            label: 'Валюта ціни закупівлі (т)',
            type: 'select',
            resource: 'Setting.ListCurrency',
            inputProps: {
                disabled: true
            },
            defaultValue: DefaultValueConfig.currencyDefaultValue
        },
        transportPriceUAH: {
            label: 'Вартість транспорту',
            type: 'number'
        },
        loadingLocation: {
            label: 'Місце завантаження',
            type: 'select',
            isRequired: true,
            resource: 'Setting.ListLoadingLocation'
        },
        unloadingLocation: {
            label: 'Місце вивантаження',
            isRequired: true,
            type: 'select',
            resource: 'Company.Warehouse'
        },
        carrier: {
            label: 'Перевізник',
            type: 'select',
            resource: 'Purchase.Carrier',
            isLink: true
        },
        railwayStation: {
            label: 'Залізнична станція',
            type: 'select',
            resource: 'Setting.ListRailwayStation',
        },
        isUnloadedConfirmed: {
            label: 'Вивантаження підтверджено',
            type: 'switch',
            inputProps: {
                disabled: true
            }
        },
        totalLoadedQuantity: {
            label: 'Всього завантажено',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        totalUnloadedQuantity: {
            label: 'Всього вивантажено',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        dateUnloading: {
            label: 'Дата вивантаження',
            type: 'date',
            inputProps: {
                disabled: true
            }
        },
        totalTargetWeight: {
            label: 'Всього залікової ваги',
            type: 'number'
        },
    },
    drawer: {
        width: 1200,
        eventHandlers: {
            onButtons: {
                factShipment: ({ buttons, crudContext, content }) => {
                    
                    const { factShipmentButton, FactShipmentModal } = useFactShipmentButton(crudContext);

                    buttons.factShipment = factShipmentButton;
                    content.push(<FactShipmentModal key='FactShipmentModal' />);
                    
                }
            }
        }
    },
    form: {
        template: ApplicationFormTemplate
    },
    restUri: '/core_api/purchase/applications',
    accessFn: ( mode, item ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER'].some( role => roles.includes( role )) ? true : false
        };

        const itemIsUnloadedConfirmed = item?.isUnloadedConfirmed ?? item?._original?.isUnloadedConfirmed;
        const itemResponsibleId = item?.responsible?.id ?? item?._original?.responsible?.id;

        if( 
            item 
            && !itemIsUnloadedConfirmed
            && ['ROLE_BUYER'].some( role => roles.includes( role )) 
            && itemResponsibleId === UserStore.user.id
        ) {
            rules.update = true;
            rules.delete = true;
        }

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};